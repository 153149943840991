import qs from 'qs';
import exodusInstance from 'lib/api/instances/exodus';
import requestConfigExodus from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import {
  HoldingCompositionParams,
  HoldingCompositionResponse,
} from 'features/company/slice.types';
import { CorpActionCategoryKeys } from 'features/company/constants';
import axios from 'axios';
import { CompanyChartType } from 'features/company/FRPage/types';
import {
  iGetTopBrokerParams,
  iTopBrokerData,
} from 'global/MainLayout/SideWidget/BandarDetector/types';
import {
  AnalystRating,
  Company,
  CompanyDailyChart,
  CompanyHotList,
  CompanyTrending,
  CompanyKeystats,
  CompanyInfo,
  CompanySubsidiary,
  CompanyFRFinData,
  CompanyPriceFeedClose,
} from '../../../@types/company';
import { APIPromiseResponse, APIResponse } from '../../../@types/api-response';

import { GetTradeBookParams, GetTradeBookRes } from '../../../@types/tradebook';
import { SearchWatchlist } from '../../../@types/watchlist';

const marketDetectorURL = `${API_EXODUS_URL}/marketdetectors`;
const findataViewURL = `${API_EXODUS_URL}/findata-view/marketdetectors`;
const seasonalityURL = `${API_EXODUS_URL}/seasonality`;
const emittenURL = `${API_EXODUS_URL}/emitten`;
const hotlistURL = `${emittenURL}/hotlist`;
const companyPriceFeedURL = `${API_EXODUS_URL}/company-price-feed`;
const emittenMetadataURL = `${API_EXODUS_URL}/emitten-metadata`;
const orderTradeURL = `${API_EXODUS_URL}/order-trade`;

const requestExodus = exodusInstance.noAlert;

// type aliases
type CompanyResponse<DataToken> = () => Promise<APIResponse<DataToken>>;

// trending
const getTrendingCompany: CompanyResponse<[CompanyTrending]> = () =>
  requestExodus.get(`${API_EXODUS_URL}/emitten/trending`);

const getIHSG: CompanyResponse<Company> = () =>
  requestExodus.get(`${API_EXODUS_URL}/emitten/IHSG/info`);

const getTendingCompanyPrices = ({
  symbol,
  interval = '5',
}: {
  symbol: string | string[];
  interval?: string;
}): Promise<APIResponse<[CompanyPriceFeedClose]>> => {
  if (typeof symbol === 'string') {
    return requestExodus.get(
      `${API_EXODUS_URL}/company-price-feed/prices/close`,
      {
        params: {
          interval,
          symbol,
        },
      },
    );
  }

  return requestExodus.get(
    `${API_EXODUS_URL}/company-price-feed/prices/close`,
    {
      params: {
        interval,
        symbol,
      },
      paramsSerializer: (params) => qs.stringify(params),
    },
  );
};

// Hotlist
const getTopGainer: CompanyResponse<CompanyHotList> = () =>
  requestExodus.get(`${hotlistURL}/topgainer?limit=10`);

const getTopLoser: CompanyResponse<CompanyHotList> = () =>
  requestExodus.get(`${hotlistURL}/toploser?limit=10`);

const getMostActive: CompanyResponse<CompanyHotList> = () =>
  requestExodus.get(`${hotlistURL}/mostactive?limit=10`);

/**
 * Search company with keyword
 */
const getSearchCompany = (params: {
  keyword: string;
  page: number;
  watchlist_id: number;
}): Promise<APIResponse<SearchWatchlist>> =>
  requestExodus.get('/watchlist/search/company', { params });

const getCompanyInfo = (symbol: string): Promise<APIResponse<CompanyInfo>> =>
  requestExodus.get(`${emittenURL}/${symbol}/info`);

/**
 * Get company profile info
 * @param {string} symbol
 * @return {APIResponse}
 */
const getCompanyProfile = (symbol) =>
  requestExodus.get(`${emittenURL}/${symbol}/profile`);

const getCompanyChart = (
  symbol: string,
  timeframe: string,
): Promise<APIResponse<CompanyDailyChart>> => {
  const includePrevious = timeframe === 'ytd' || timeframe === '1w';

  return requestExodus.get(`${API_EXODUS_URL}/charts/${symbol}/daily`, {
    params: {
      timeframe,
      ...(includePrevious && { is_include_previous_historical: true }),
    },
  });
};

const getMarketDetector = (
  symbol: string,
  params: {
    from?: Date;
    to?: Date;
    transaction_type?: string;
    market_board?: string;
    investor_type?: string;
    limit?: number;
  },
): Promise<APIResponse> =>
  requestExodus.get(`${marketDetectorURL}/${symbol}`, { params });

const getTopBroker = (
  params: iGetTopBrokerParams,
): Promise<APIResponse<iTopBrokerData>> =>
  requestExodus.get('order-trade/broker/top', { params });

const getBrokerList = (params: {
  page: number;
  limit: number;
  group: string;
}): Promise<APIResponse> =>
  requestExodus.get(`${findataViewURL}/brokers`, {
    params,
  });

const getMarketDetectorActivity = (
  brokerCode: string,
  params: {
    from: string;
    to: string;
    transaction_type: string;
    market_board: string;
    page: number;
    limit: number;
    investor_type: string;
  },
): Promise<APIResponse> =>
  requestExodus.get(`${findataViewURL}/activity/${brokerCode}/detail`, {
    params,
  });

const getAllCorpAction = (symbol: string, limit = 30): APIPromiseResponse =>
  requestExodus.get(`${API_EXODUS_URL}/corpaction/${symbol}`, {
    params: {
      limit,
    },
  });

const getCompanyCorpAction = (
  symbol: string,
  category: CorpActionCategoryKeys,
) =>
  requestExodus.get(`${API_EXODUS_URL}/corpaction/${category}`, {
    params: { symbol },
  });

/**
 * Get company major holder, related to get company insider
 * @param {Object} props
 * @param {string=} props.insider
 * @param {string} props.symbol
 * @param {string=} props.datestart
 * @param {string=} props.dateend
 * @param {number=} props.page
 * @returns {APIResponse}
 */
const getCompanyMajorholder = ({
  insider = '',
  symbol,
  datestart = '',
  dateend = '',
  page,
}) =>
  requestExodus.get(`${API_EXODUS_URL}/insider/company/majorholder`, {
    params: {
      insider,
      symbol,
      date_start: datestart,
      date_end: dateend,
      page,
    },
  });

/**
 * Get company seasonality data
 * @param {string} symbol
 * @param {string|number} year
 * @param {string|number} backyear
 * @return {APIResponse}
 */
const getCompanySeasonality = (symbol, year, backyear) =>
  requestExodus.get(`${seasonalityURL}/${symbol}`, {
    params: { year, back_year: backyear },
  });

const getCompanyKeystat = (symbol): APIPromiseResponse<CompanyKeystats> =>
  requestExodus.get(`${API_EXODUS_URL}/keystats/ratio/v1/${symbol}`, {
    params: {
      year_limit: 10,
    },
  });

/**
 * Get financial reports
 * @param {Object} params
 * @param {string} params.symbol
 * @param {string} params.datatype
 * @param {string} params.reporttype
 * @param {string} params.statement
 * @return {APIResponse}
 */
const getFinancialReport = ({
  symbol,
  datatype = 1,
  reporttype,
  statement,
}) => {
  const url = `${API_EXODUS_URL}/findata-view/company/financial`;
  return requestExodus
    .get(url, {
      params: {
        symbol,
        data_type: datatype,
        report_type: reporttype,
        statement_type: statement,
      },
    })
    .then((res) => {
      const newRes = { ...res.data };
      newRes.data.defaultCurrency = newRes.data.default_currency;
      newRes.data.htmlReport = newRes.data.html_report;
      newRes.data.rounding_value = {
        idr: newRes.data.rounding_value[0],
        usd: newRes.data.rounding_value[1],
      };

      delete newRes.data.default_currency;
      delete newRes.data.html_report;

      return newRes;
    });
};

/**
 * Get Analyst Rating
 * @param {string} symbol
 * @return {APIResponse}
 */
const getAnalystRating = (symbol): Promise<APIResponse<AnalystRating>> =>
  requestExodus.get(`analyst-ratings/${symbol}`);

const getAnalystConsensus = (symbol): Promise<APIResponse<AnalystRating>> =>
  requestExodus.get(`analyst-ratings/${symbol}/consensus`);

const getCompanyHoldingCompositionToken = () =>
  requestExodus.post(`${emittenMetadataURL}/shareholders/token`);

const getCompanyHoldingComposition = async ({
  symbol,
  type,
  timeframe,
  token,
  isFromWebview,
  tries = 0,
}: HoldingCompositionParams): Promise<
  APIResponse<HoldingCompositionResponse>
> => {
  /**
   *  we use `requestExodusNoResIntercept` because
   *  we don't want to fall back to the refreshToken handler when BE returns 401.
   */
  const getToken = async () => {
    const tokenResponse = await getCompanyHoldingCompositionToken();
    const { data } = tokenResponse;
    return data?.data?.value as string;
  };

  const Authorization = token || (!isFromWebview && (await getToken())) || '';

  try {
    if (!Authorization) throw Error('No Access Token found');

    const res = await axios.get(
      `${emittenMetadataURL}/shareholders/${symbol}/chart`,
      {
        params: {
          symbol,
          value_year: timeframe,
          shareholder_type: type,
        },
        headers: { Authorization },
      },
    );
    return res;
  } catch (err) {
    const statusCode = err?.cause?.status ?? 401;
    if (isFromWebview || statusCode !== 401 || tries >= 2) throw err;

    const newToken = await getToken();
    return getCompanyHoldingComposition({
      symbol,
      type,
      timeframe,
      isFromWebview,
      token: newToken,
      tries: tries + 1,
    });
  }
};

/**
 * Get company contact
 * @param {string} symbol
 * @return {APIResponse}
 */
const getCompanyContact = (symbol) =>
  requestExodus.get(`${emittenURL}/${symbol}/contact`);

const getTradeBook = (
  params: GetTradeBookParams,
): Promise<APIResponse<GetTradeBookRes>> =>
  requestExodus.get(`${orderTradeURL}/trade-book`, {
    params,
  });

const getInitRunningTrade = (
  params?: GetInitRunningTradeParams,
): Promise<APIResponse<GetInitRunningTradeRes>> =>
  requestExodus.get(`${companyPriceFeedURL}/running-trade`, {
    params,
  });

const getInitRunningTradeV2 = (
  params?: GetInitRunningTradeParamsV2,
): Promise<APIResponse<GetInitRunningTradeRes>> =>
  requestExodus.get(`${companyPriceFeedURL}/v2/running-trade`, {
    params,
  });

const getCompanySubsidiary = (
  symbol: string,
): Promise<APIResponse<CompanySubsidiary>> =>
  requestExodus.get(`${emittenMetadataURL}/subsidiary/${symbol}`);

const getCompanyInfoV2 = (
  symbol: string,
  exchange: string,
): Promise<APIResponse<any>> =>
  requestExodus.get(`${emittenURL}/v2/${exchange}/${symbol}/info`);

const getCompanyChartV2 = (
  symbol: string,
  timeframe: string,
): Promise<APIResponse<CompanyChartType>> =>
  requestExodus.get(`${API_EXODUS_URL}/charts/${symbol}`, {
    params: { timeframe },
  });

const getCompanyFinItem = (
  symbol: string,
  exchange: string,
): Promise<APIResponse<CompanyFRFinData>> =>
  requestExodus.get(`${emittenURL}/v2/${exchange}/${symbol}/fin-items`);

const getGuestCompanyV2 = (
  symbol: string,
  exchange: string,
): Promise<APIResponse<any>> => {
  const reqDetail = requestConfigExodus(false);
  return reqDetail.get(
    `${API_EXODUS_URL}/emitten/non-login/v2/${exchange}/${symbol}/info`,
  );
};

const getGuestCompanyChartV2 = (
  symbol: string,
  timeframe: string,
): Promise<APIResponse<CompanyChartType>> => {
  const reqDetail = requestConfigExodus(false);
  return reqDetail.get(`${API_EXODUS_URL}/partnership/charts/${symbol}`, {
    params: { timeframe },
  });
};

const getGuestCompanyFinItem = (
  symbol: string,
  exchange: string,
): Promise<APIResponse<CompanyFRFinData>> =>
  axios.get(
    `${API_EXODUS_URL}/emitten/non-login/v2/${exchange}/${symbol}/fin-items`,
  );

export default {
  getTrendingCompany,
  getIHSG,
  getTendingCompanyPrices,
  getSearchCompany,
  getCompanyProfile,
  getCompanyInfo,
  getCompanyChart,
  getAnalystRating,
  getAnalystConsensus,
  getCompanyContact,
  getCompanyInfoV2,
  getCompanyChartV2,
  getCompanyFinItem,

  // Hotlist
  getTopGainer,
  getTopLoser,
  getMostActive,

  // bandar detector
  getMarketDetector,
  getBrokerList,
  getMarketDetectorActivity,
  getTopBroker,

  // related to profile APIs
  getCompanyCorpAction,
  getCompanyMajorholder,
  getCompanySeasonality,
  getCompanyKeystat,
  getFinancialReport,
  getAllCorpAction,

  // used for trading modal
  getCompanyHoldingCompositionToken,
  getCompanyHoldingComposition,

  // Company Price Feed,
  getTradeBook,
  getInitRunningTrade,
  getCompanySubsidiary,
  getInitRunningTradeV2,

  getGuestCompanyV2,
  getGuestCompanyChartV2,
  getGuestCompanyFinItem,
};
